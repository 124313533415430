import './../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import LoadingSpinner from '../../shell/loading-spinner';
import AccordionHeader from '../../shell/accordion-header';
import PageHeader from '../../shell/page-header';
import AccordionWidget from '../../shell/accordion-widget';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { ServiceIssueResults } from '../../datamodels/serviceissueresults.model';

interface ServiceIssuePageProps {}

const ServiceIssuePage: React.FunctionComponent<ServiceIssuePageProps> = () => {
  const { productId, serviceId, issueLogLineId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<ServiceIssueResults>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<ServiceIssueResults>(
          `products/${productId}/services/${serviceId}/issues/${issueLogLineId}/results`,
        )
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, serviceId]);

  if (stageData === undefined || productId === undefined || serviceId === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={stageData.serviceName}
        subTitle={stageData.issueLogLine.substring(0, 150)}
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <AccordionHeader
              caption="Failures:"
              smallCaption={'(Last 72 hours)'}
              boldCaption={stageData.results.length}
            />
            <Accordion.Body style={{}}>
              <Grid className="rr-equal-columns" scrollable="none" data={stageData.results}>
                <GridColumn field="createdAt" title="CreatedAt" width="180px" />
                <GridColumn field="testCase" title="Test Case" />
                <GridColumn
                  field="frootGuid"
                  title="Froot"
                  width="220px"
                  cell={(props) => (
                    <td style={{ color: 'darkgoldenrod', textDecoration: 'underline' }}>
                      <a
                        href={`https://froot-new.azurewebsites.net/progress/${props.dataItem.frootGuid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.dataItem.frootGuid}
                      </a>
                    </td>
                  )}
                />
              </Grid>
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(ServiceIssuePage) as typeof ServiceIssuePage;
