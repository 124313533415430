import './../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeaderIconModel } from '../datamodels/draweritem.model';

interface PageHeaderProps {
  mainTitle: string;
  subTitle?: string;
  customBump?: string;
  icons?: PageHeaderIconModel[];
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  mainTitle,
  subTitle,
  icons,
  customBump,
}) => {
  const iconsToShow = icons?.map((item, i) => (
    <a
      className="pc-header-icon"
      key={i}
      style={{ fontSize: '22px', paddingTop: '1px' }}
      href={item.href}
      onClick={item.onClick}
      target="_blank"
      rel="noreferrer"
    >
      <i
        className={'fa ' + item.icon}
        style={{ marginLeft: '10px', marginRight: '8px', verticalAlign: 'bottom' }}
      />
      <span style={{ fontSize: '15px', verticalAlign: 'bottom' }}>{item.caption}</span>
    </a>
  ));

  return (
    <ol className="pc-breadcrumb">
      <Link className="pc-header-back-icon" to={customBump ? customBump : `../..`} relative="path">
        <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
      </Link>
      <li style={{ display: 'inline-block', marginLeft: '2px' }}>
        <div style={{ fontSize: '22px' }}>{mainTitle}</div>
        <div style={{ fontSize: '14px', marginLeft: '1px', marginTop: '1px', marginBottom: '2px' }}>
          {subTitle}
        </div>
      </li>
      {iconsToShow}
    </ol>
  );
};

export default React.memo(PageHeader) as typeof PageHeader;
