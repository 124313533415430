import './../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { Accordion } from 'react-bootstrap';
import AccordionWidget from '../../shell/accordion-widget';
import { BuildStageFlavorModel } from '../../datamodels/buildstageflavor.model';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import LoadingSpinner from '../../shell/loading-spinner';
import PageHeader from '../../shell/page-header';
import PullrequestFlavorPageByIssue from './pullrequest-flavor-page-by-issue';
import PullRequestFlavorByTestPage from './pullrequest-flavor-page-by-test';

interface PullRequestFlavorPageProps {}

const PullRequestFlavorPage: React.FunctionComponent<PullRequestFlavorPageProps> = () => {
  const { productId, stageFlavorId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<BuildStageFlavorModel>();
  const [selected, setSelected] = React.useState<number>(
    localStorage.getItem('PrFlavorSelected') ? +localStorage.getItem('PrFlavorSelected')! : 0,
  );

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<BuildStageFlavorModel>(
          `products/${productId}/stageflavors/${stageFlavorId}?pastDays=1`,
        )
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, stageFlavorId]);

  if (stageData === undefined || productId === undefined || stageFlavorId === undefined) {
    return <LoadingSpinner />;
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
    localStorage.setItem('PrFlavorSelected', e.selected.toString());
  };

  return (
    <div>
      <PageHeader
        mainTitle={`${stageData.testSuite} Failure Analysis`}
        subTitle={stageData.testSet}
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <Accordion.Body style={{ display: 'table', marginTop: '10px' }}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="By Error">
                  <PullrequestFlavorPageByIssue failureModalities={stageData.failureModalities} />
                </TabStripTab>
                <TabStripTab title="By Test">
                  <PullRequestFlavorByTestPage testFailures={stageData.testFailures} />
                </TabStripTab>
              </TabStrip>
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(PullRequestFlavorPage) as typeof PullRequestFlavorPage;
