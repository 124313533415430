import './../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionWidget from '../../shell/accordion-widget';
import AccordionHeader from '../../shell/accordion-header';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import PageHeader from '../../shell/page-header';
import LoadingSpinner from '../../shell/loading-spinner';
import { TrainBuildDetailsModel, TrainBuildSuite } from '../../datamodels/train.model';
import TrainBuildPageMatrix from './train-build-page-matrix';

interface TrainBuildPageProps {}

const TrainBuildPage: React.FunctionComponent<TrainBuildPageProps> = () => {
  const { productId, adoBuildId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<TrainBuildDetailsModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<TrainBuildDetailsModel>(`products/${productId}/trainbuilds/${adoBuildId}`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, productId]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  const generateCells = () => {
    const serviceCells = stageData.trainBuildSuites.map((suiteItem: TrainBuildSuite, i: number) => {
      let passRate: string | undefined;
      if (suiteItem.totalTestCount > 0) {
        const passRateNum = (suiteItem.passedTestCount / suiteItem.totalTestCount) * 100;
        passRate = passRateNum.toFixed(1) + '%';
      }

      return (
        <div className="row" key={i} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <Accordion.Item eventKey="0" style={{ padding: '0' }}>
            <AccordionWidget>
              <AccordionHeader
                caption={suiteItem.testSuite + ':'}
                boldCaption={passRate ? passRate : ''}
                smallCaption={
                  passRate
                    ? `passed tests (${suiteItem.passedTestCount} of ${suiteItem.totalTestCount})`
                    : 'No Tests'
                }
              />
              <AccordionBody>
                <TrainBuildPageMatrix
                  inputMatrix={suiteItem}
                  stageColumnWidth={200}
                  testColumnTitle={'Tests Never Passed'}
                  testSuiteId={suiteItem.testSuiteId}
                />
              </AccordionBody>
            </AccordionWidget>
          </Accordion.Item>
          <div style={{ height: '20px' }}></div>
        </div>
      );
    });
    return serviceCells;
  };
  return (
    <div>
      <PageHeader
        mainTitle={`Build ${stageData.buildNumber}`}
        subTitle={stageData.train}
        customBump=".."
      />
      <div>
        {stageData.trainBuildSuites.length > 0 && (
          <Accordion defaultActiveKey="0">{generateCells()}</Accordion>
        )}
      </div>
    </div>
  );
};

export default React.memo(TrainBuildPage) as typeof TrainBuildPage;
