import './../../../App.css';
import React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import LoadingSpinner from '../../../shell/loading-spinner';
import { TestCaseByStageModel } from '../../../datamodels/testcasebybuildstage.model';
import { Link } from 'react-router-dom';

interface StageAnalysisMatrixProps {
  inputMatrix?: TestCaseByStageModel;
  testSuiteId?: number;
  stageColumnWidth?: number;
  testColumnTitle?: string;
}

const StageAnalysisMatrix: React.FunctionComponent<StageAnalysisMatrixProps> = ({
  inputMatrix,
  stageColumnWidth,
  testColumnTitle,
  testSuiteId,
}) => {
  const columnsToShow = inputMatrix?.buildStages.map((item, i) => (
    <GridColumn
      field="name"
      title={item.buildNumber}
      width={stageColumnWidth ? stageColumnWidth : '100px'}
      key={i}
      headerCell={(props) => (
        <div>
          {' '}
          <span className="rr-center-column">{props.title}</span>{' '}
          {testColumnTitle && <span className="rr-center-column">{'Attempt ' + item.attempt}</span>}
        </div>
      )}
      cell={(props) => (
        <td
          style={{
            textAlign: 'center',
            color:
              props.dataItem.buildStageResults[i].netResult == 'Fail'
                ? 'red'
                : props.dataItem.buildStageResults[i].netResult == 'Pass'
                ? 'black'
                : 'darkgoldenrod',
          }}
        >
          <Link to={`buildstages/${props.dataItem.buildStageResults[i].buildStageId}`}>
            {props.dataItem.buildStageResults[i].netResult}
          </Link>
        </td>
      )}
    />
  ));

  if (inputMatrix === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Grid className="rr-equal-columns" data={inputMatrix.tests} scrollable="none">
        <GridColumn
          field="testCase"
          title={testColumnTitle ? testColumnTitle : 'Test'}
          cell={(props) => (
            <td style={{ textDecoration: 'underline' }}>
              <Link
                to={
                  testSuiteId
                    ? `testsuites/${testSuiteId}/testcases/${props.dataItem.testCaseId}`
                    : `testcases/${props.dataItem.testCaseId}`
                }
              >
                {props.dataItem.testCase}
              </Link>
            </td>
          )}
        />
        {columnsToShow}
      </Grid>
    </div>
  );
};

export default React.memo(StageAnalysisMatrix) as typeof StageAnalysisMatrix;
