import './../../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Grid, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import PageHeader from '../../../shell/page-header';
import AccordionWidget from '../../../shell/accordion-widget';
import AccordionHeader from '../../../shell/accordion-header';
import LoadingSpinner from '../../../shell/loading-spinner';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import {
  BuildStageTestResult,
  StageAnalysisResultsModel,
} from '../../../datamodels/stageanalysisresults.model';

interface StageAnalysisTestPageProps {}

const StageAnalysisTestPage: React.FunctionComponent<StageAnalysisTestPageProps> = () => {
  const intl = useInternationalization();
  const { productId, stageFlavorId, testCaseId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<StageAnalysisResultsModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<StageAnalysisResultsModel>(
          `products/${productId}/stageFlavors/${stageFlavorId}/analysis/testcases/${testCaseId}`,
        )
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  const getAdoHref = (adoTypeId: number, adoId: number, adoEnvironmentId: number) => {
    if (adoTypeId === 0) {
      return `https://dev.azure.com/domoreexp/Teamspace/_build/results?buildId=${adoId}`;
    }
    return `https://dev.azure.com/domoreexp/Teamspace/_releaseProgress?_a=release-environment-logs&releaseId=${adoId}&environmentId=${adoEnvironmentId}`;
  };

  const testResultRows = (props: GridDetailRowProps) => {
    const resultRows = props.dataItem.results.map((resultItem: BuildStageTestResult, j: number) => {
      return (
        <div
          key={j}
          style={{
            fontSize: '14px',
            fontWeight: '400',
            display: 'block',
          }}
        >
          <div
            style={{
              display: 'inline-flex',
            }}
          >
            <div style={{ width: '40px' }}></div>
            <div style={{ width: '200px' }}>
              <a
                style={{
                  paddingLeft: '12px',
                  color: 'darkgoldenrod',
                  textDecoration: 'underline',
                }}
                href={`https://froot-new.azurewebsites.net/progress/${resultItem.frootGuid}`}
                target="_blank"
                rel="noreferrer"
              >
                {resultItem.frootGuid}
              </a>
            </div>
            <div style={{ width: '100px' }}>{resultItem.testOutcome}</div>
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {resultItem.issueId > 0 ? resultItem.issueName : ''}
            </div>
          </div>
        </div>
      );
    });

    return resultRows;
  };

  return (
    <div>
      <PageHeader
        mainTitle={`Pipeline Stage ${stageData.stageFlavorName}`}
        subTitle={stageData.testCase}
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <AccordionHeader caption="Recent Results:" boldCaption={stageData.buildStages.length} />
            <Accordion.Body style={{ display: 'table' }}>
              <Grid data={stageData.buildStages} detail={testResultRows}>
                <GridColumn
                  field="buildNumber"
                  title="Build Number"
                  width="238px"
                  cell={(props) => (
                    <a
                      style={{
                        paddingLeft: '12px',
                        color: 'darkgoldenrod',
                        textDecoration: 'underline',
                      }}
                      href={getAdoHref(
                        props.dataItem.adoTypeId,
                        props.dataItem.adoId,
                        props.dataItem.adoEnvironmentId,
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {props.dataItem.buildNumber}
                    </a>
                  )}
                />
                <GridColumn field="netResult" title="Result" width="100px" />
                <GridColumn field="webBuild" title="Web Build" width="140px" />
                <GridColumn
                  field=""
                  title="Start Time"
                  width="150px"
                  cell={(props) => (
                    <td>
                      {intl.formatDate(new Date(props.dataItem.startTime), 'dd-MM-yyyy HH:mm')}
                    </td>
                  )}
                />
                <GridColumn field="" title=""></GridColumn>
              </Grid>{' '}
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(StageAnalysisTestPage) as typeof StageAnalysisTestPage;
