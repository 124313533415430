import './../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { useInternationalization } from '@progress/kendo-react-intl';
import LoadingSpinner from '../../shell/loading-spinner';
import { FailureModalityModel } from '../../datamodels/buildstageflavor.model';

const initialSort: Array<SortDescriptor> = [{ field: 'pullRequestCount', dir: 'desc' }];

interface PullRequestFlavorByIssuePageProps {
  failureModalities?: FailureModalityModel[];
}

const PullRequestFlavorByIssuePage: React.FunctionComponent<PullRequestFlavorByIssuePageProps> = ({
  failureModalities,
}) => {
  const intl = useInternationalization();
  const [sort, setSort] = React.useState(initialSort);

  if (failureModalities === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Grid
        data={orderBy(failureModalities, sort)}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
      >
        <GridColumn field="issueType" title="Issue Type" width="130px" />
        <GridColumn
          field="issueName"
          title="Description"
          cell={(props) => (
            <td style={{ paddingLeft: '6px', textDecoration: 'underline', color: 'darkgoldenrod' }}>
              <Link
                to={`issuetypes/${props.dataItem.issueTypeId}/issues/${props.dataItem.issueId}`}
              >
                {props.dataItem.issueName}
              </Link>
            </td>
          )}
        />
        <GridColumn
          field="pullRequestCount"
          title="PR Count"
          width="100px"
          cell={(props) => (
            <td>
              <span style={{ float: 'right', marginRight: '10px' }}>
                {props.dataItem.pullRequestCount}
              </span>
            </td>
          )}
        />
        <GridColumn
          field="resultCount"
          title="Occurences"
          width="100px"
          cell={(props) => (
            <td>
              <span style={{ float: 'right', marginRight: '10px' }}>
                {props.dataItem.resultCount}
              </span>
            </td>
          )}
        />
        <GridColumn
          field="lastFail"
          title="Last Failure"
          width="140px"
          cell={(props) => (
            <td>{intl.formatDate(new Date(props.dataItem.lastFail), 'dd-MM-yyyy HH:mm')}</td>
          )}
        />
      </Grid>
    </div>
  );
};

export default React.memo(PullRequestFlavorByIssuePage) as typeof PullRequestFlavorByIssuePage;
