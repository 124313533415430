import './../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import SectionHeader from '../../shell/section-header';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { TrainBuildModel } from '../../datamodels/train.model';
import LoadingSpinner from '../../shell/loading-spinner';

interface TrainsNavigationProps {
    productId: number;
}

const TrainsNavigation: React.FunctionComponent<TrainsNavigationProps> = ({
    productId,
}) => {
    const intl = useInternationalization();
    const { getDataApi } = useRiderServiceProvider();
    const [stageData, setStageData] = React.useState<TrainBuildModel[]>([]);

    React.useEffect(() => {
        getDataApi().then((dataApi) => {
            dataApi
                .get<TrainBuildModel[]>(`products/${productId}/trainbuilds`)
                .then((res) => {
                    setStageData(res.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }, [getDataApi, productId]);

    if (stageData === undefined) {
        return <LoadingSpinner />;
    }
      
    return (
        <>
            <div>
                <SectionHeader mainTitle="Recent Train Builds"></SectionHeader>      
                <div>
                    {stageData.length > 0 && (
                        <Grid scrollable="none" data={stageData}>
                            <GridColumn field="buildNumber" title="Build Number" width="100px" cell={(props) => (
                                <td style={{ textDecoration: 'underline' }}>
                                    <Link to={`${props.dataItem.adoId}`}>
                                        {props.dataItem.buildNumber}
                                    </Link>
                                </td>
                            )}/>   
                            <GridColumn
                                field=""
                                title="Build Date"
                                width="140px"
                                cell={(props) => (
                                    <td>{intl.formatDate(new Date(props.dataItem.buildTime), 'MM-dd-yyyy HH:mm')}</td>
                                )}
                            />      
                            <GridColumn field="train" title="Train" width="100px" />
                            <GridColumn field="sourceBranch" title="Branch"/>                   
                        </Grid>
                    )}
                </div>         
            </div>
        </>
    );
};

export default React.memo(TrainsNavigation) as typeof TrainsNavigation;
