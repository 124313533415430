import './../App.css';
import React from 'react';
import { useAccordionButton } from 'react-bootstrap';

interface AccordionToggleProps {
  eventKey: string;
  children: React.ReactNode;
}

const AccordionToggle: React.FunctionComponent<AccordionToggleProps> = ({ eventKey, children }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log('toggle clicked'));
  return (
    <button
      type="button"
      style={{
        display: 'block',
        fontSize: '30px',
        padding: '0px',
        marginTop: '2px',
        outline: 'none',
        borderWidth: '0px',
        backgroundColor: 'transparent',
        float: 'right',
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
};

export default React.memo(AccordionToggle) as typeof AccordionToggle;
