import React from 'react';
import 'hammerjs';
import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import ShellRoot from './shell/shell-root';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { IntlProvider } from '@progress/kendo-react-intl';
import ConfigurationProvider from './shell/configuration-provider';

function App() {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <ConfigurationProvider>
      <BrowserRouter basename="/">
        <IntlProvider locale={'en-us'}>
          <Routes>
            <Route path="/*" element={<ShellRoot />} />
          </Routes>
        </IntlProvider>
      </BrowserRouter>
    </ConfigurationProvider>
  );
}

export default App;
