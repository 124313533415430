import './../../App.css';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useInternationalization } from '@progress/kendo-react-intl';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import PageHeader from '../../shell/page-header';
import { GateWithBuildsModel } from '../../datamodels/gate.model';
import LoadingSpinner from '../../shell/loading-spinner';


interface FlightGatePageProps {}

const FlightGatePage: React.FunctionComponent<FlightGatePageProps> = () => {
  const intl = useInternationalization();
  const { productId, gateId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<GateWithBuildsModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
        dataApi
            .get<GateWithBuildsModel>(`products/${productId}/releases/${gateId}`)
            .then((res) => {
                setStageData(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    });
}, [getDataApi, productId]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={`Release Flight`}
        subTitle={stageData.gateName}
        customBump='..'
      /><div>
      {stageData.recentBuilds.length > 0 && (
          <Grid scrollable="none" data={stageData.recentBuilds}>
            <GridColumn field="teamsBuild" title="Build" width="140px" cell={(props) => (
                  <td style={{ textDecoration: 'underline' }}>
                      <Link to={`builds/${props.dataItem.gateBuildId}`}>
                          {props.dataItem.teamsBuild}
                      </Link>
                  </td>
              )} />
            <GridColumn
                field="effectiveDate"
                title="Effective"
                width="150px"
                cell={(props) => (
                    <td>{intl.formatDate(new Date(props.dataItem.effectiveDate), 'MM-dd-yyyy HH:mm')}</td>
                )}
            />
            <GridColumn field="sourceBranch" title="Source Branch" />            
          </Grid>
      )}
  </div>
    </div>
  );
};

export default React.memo(FlightGatePage) as typeof FlightGatePage;
