import './../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import PageHeader from '../../shell/page-header';
import { GateBuildModel } from '../../datamodels/gatebuild.model';
import LoadingSpinner from '../../shell/loading-spinner';

interface FlightBuildPageProps {}

const FlightBuildPage: React.FunctionComponent<FlightBuildPageProps> = () => {
  const { productId, gateId, gateBuildId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<GateBuildModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<GateBuildModel>(`products/${productId}/releases/${gateId}/builds/${gateBuildId}`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PageHeader mainTitle={stageData.gateName} subTitle={stageData.teamsBuild} />
    </div>
  );
};

export default React.memo(FlightBuildPage) as typeof FlightBuildPage;
