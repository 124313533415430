import './../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import SectionHeader from '../../shell/section-header';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { GateModel } from '../../datamodels/gate.model';

interface FlightsNavigationProps {
    productId: number;
}

const FlightsNavigation: React.FunctionComponent<FlightsNavigationProps> = ({
    productId,
}) => {
    const intl = useInternationalization();
    const { getDataApi } = useRiderServiceProvider();
    const [stageData, setStageData] = React.useState<GateModel[]>([]);

    React.useEffect(() => {
        getDataApi().then((dataApi) => {
            dataApi
                .get<GateModel[]>(`products/${productId}/releases`)
                .then((res) => {
                    setStageData(res.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }, [getDataApi, productId]);

    return (
        <>
            <div>
                <SectionHeader mainTitle="Release Flights"></SectionHeader>
                <div>
                    {stageData.length > 0 && (
                        <Grid scrollable="none" data={stageData}>
                            <GridColumn field="ring" title="Ring" width="100px" />
                            <GridColumn field="platform" title="Platform" width="100px" />
                            <GridColumn field="isExperimental" title="isExperimental" width="100px" />
                            <GridColumn field="gateName" title="Flight"  cell={(props) => (
                                    <td style={{ textDecoration: 'underline' }}>
                                        <Link to={`${props.dataItem.gateId}`}>
                                            {props.dataItem.gateName}
                                        </Link>
                                    </td>
                                )} />
                            <GridColumn
                                field="mostRecentTeamsBuild"
                                title="Teams Build"
                                width="200px"
                                cell={(props) => (
                                    <td style={{ textDecoration: 'underline' }}>
                                        <Link to={`builds/${props.dataItem.adoBuildId}`}>
                                            {props.dataItem.mostRecentTeamsBuild}
                                        </Link>
                                    </td>
                                )}
                            />
                            <GridColumn
                                field="lastFail"
                                title="Effective"
                                width="140px"
                                cell={(props) => (
                                    <td>{intl.formatDate(new Date(props.dataItem.effectiveDate), 'dd-MM-yyyy HH:mm')}</td>
                                )}
                            />
                        </Grid>
                    )}
                </div>
            </div>
        </>
    );
};

export default React.memo(FlightsNavigation) as typeof FlightsNavigation;
