import './../../App.css';
import React from 'react';
import SectionHeader from '../../shell/section-header';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { BuildStageFlavorModel } from '../../datamodels/buildstageflavor.model';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom';

interface PullRequestNavigationProps {
  productId: number;
}

const PullRequestNavigation: React.FunctionComponent<PullRequestNavigationProps> = ({
  productId,
}) => {
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<BuildStageFlavorModel[]>([]);

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<BuildStageFlavorModel[]>(`products/${productId}/ptypes/1`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, productId]);

  return (
    <>
      <div>
        <SectionHeader mainTitle="Pull Request Reliability"></SectionHeader>
        <div>
          {stageData.length > 0 && (
            <Grid scrollable="none" data={stageData}>
              <GridColumn
                field="testSuite"
                title="Test Suite"
                width="200px"
                cell={(props) => (
                  <td style={{ textDecoration: 'underline' }}>
                    <Link to={`stageflavors/${props.dataItem.buildStageFlavorId}`}>
                      {props.dataItem.testSuite}
                    </Link>
                  </td>
                )}
              />
              <GridColumn field="testSet" title="Test Set" />
              <GridColumn field="buildStagePassRate" title="1 Day" width="100px" format="{0:p1}" />
              <GridColumn field="buildStage7PassRate" title="7 Day" width="100px" format="{0:p1}" />
              <GridColumn
                field="buildStage14PassRate"
                title="14 Day"
                width="100px"
                format="{0:p1}"
              />
              <GridColumn
                field="buildStage28PassRate"
                title="28 Day"
                width="100px"
                format="{0:p1}"
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(PullRequestNavigation) as typeof PullRequestNavigation;
