import './../../App.css';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionWidget from '../../shell/accordion-widget';
import PageHeader from '../../shell/page-header';

interface PullRequestTestPageProps {}

const PullRequestTestPage: React.FunctionComponent<PullRequestTestPageProps> = () => {
  return (
    <div>
      <PageHeader mainTitle={`Coming Soon`} />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <Accordion.Body style={{ display: 'table', marginTop: '10px' }}></Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(PullRequestTestPage) as typeof PullRequestTestPage;
