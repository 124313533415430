import './../../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../shell/page-header';
import LoadingSpinner from '../../../shell/loading-spinner';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import { StageAnalysisModel } from '../../../datamodels/stageanalysis.model';
import AccordionWidget from '../../../shell/accordion-widget';
import AccordionHeader from '../../../shell/accordion-header';
import { Accordion } from 'react-bootstrap';
import { TestCaseByStageModel } from '../../../datamodels/testcasebybuildstage.model';
import StageAnalysisMatrix from './stageanalysis-page-matrix';
import StageAnalysisFailureModalityPage from './stageanalysis-page-modality';

interface StageAnalysisPageProps {}

const StageAnalysisPage: React.FunctionComponent<StageAnalysisPageProps> = () => {
  const { productId, stageFlavorId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<StageAnalysisModel>();
  const [matrixData, setMatrixData] = React.useState<TestCaseByStageModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<StageAnalysisModel>(`products/${productId}/stageflavors/${stageFlavorId}/analysis`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });

      dataApi
        .get<TestCaseByStageModel>(
          `products/${productId}/stageflavors/${stageFlavorId}/testcasematrix?runCount=8`,
        )
        .then((res) => {
          setMatrixData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={'Pipeline Stage ' + stageData.stageFlavorName}
        subTitle={'Failure Analysis'}
        customBump={'./..'}
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <AccordionHeader
              caption="Failing Tests Over Recent Runs:"
              boldCaption={matrixData?.tests.length}
            />
            <Accordion.Body style={{ display: 'table' }}>
              <StageAnalysisMatrix inputMatrix={matrixData} />
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
        <div style={{ height: '20px' }}></div>

        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <AccordionHeader
              caption="Failure Modalities:"
              boldCaption={stageData.failureModalities.length}
              smallCaption="(last run only)"
            />
            <Accordion.Body style={{ display: 'table' }}>
              <StageAnalysisFailureModalityPage failureModalities={stageData.failureModalities} />
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(StageAnalysisPage) as typeof StageAnalysisPage;
