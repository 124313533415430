import './../../App.css';
import React from 'react';
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { useInternationalization } from '@progress/kendo-react-intl';
import LoadingSpinner from '../../shell/loading-spinner';
import { TestPrCountModel } from '../../datamodels/buildstageflavor.model';
import { Link } from 'react-router-dom';

const initialSort: Array<SortDescriptor> = [{ field: 'pullRequestCount', dir: 'desc' }];
interface PullRequestFlavorByTestPageProps {
  testFailures?: TestPrCountModel[];
}

const PullRequestFlavorByTestPage: React.FunctionComponent<PullRequestFlavorByTestPageProps> = ({
  testFailures,
}) => {
  const intl = useInternationalization();
  const [sort, setSort] = React.useState(initialSort);

  if (testFailures === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Grid
        data={orderBy(testFailures, sort)}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
      >
        <GridColumn
          field="testCase"
          title="Test Case"
          cell={(props) => (
            <td style={{ paddingLeft: '6px', textDecoration: 'underline', color: 'darkgoldenrod' }}>
              <Link to={`tests/${props.dataItem.testCaseId}`}>{props.dataItem.testCase}</Link>
            </td>
          )}
        />
        <GridColumn field="dynamicJailFailureCount" title="Unstable" width="100px" />
        <GridColumn field="pullRequestCount" title="PR Count" width="100px" />
        <GridColumn
          field="lastFail"
          title="Last Failure"
          width="140px"
          cell={(props) => (
            <td>{intl.formatDate(new Date(props.dataItem.lastFail), 'dd-MM-yyyy HH:mm')}</td>
          )}
        />
      </Grid>
    </div>
  );
};

export default React.memo(PullRequestFlavorByTestPage) as typeof PullRequestFlavorByTestPage;
