import './../../../App.css';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import { GroupFailureModalityTests } from '../../../datamodels/testcasegroup.model';
import PageHeader from '../../../shell/page-header';
import LoadingSpinner from '../../../shell/loading-spinner';
import PipegroupMatrix from './pipegroup-matrix';
import { PageHeaderIconModel } from '../../../datamodels/draweritem.model';

interface PipeGroupFailurePageProps {}

const PipeGroupFailurePage: React.FunctionComponent<PipeGroupFailurePageProps> = () => {
  const { productId, pgroupId, issueTypeId, issueId, testCaseId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [stageData, setStageData] = React.useState<GroupFailureModalityTests>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (issueId) {
      getDataApi().then((dataApi) => {
        dataApi
          .get<GroupFailureModalityTests>(
            `products/${productId}/pgroups/${pgroupId}/issuetypes/${issueTypeId}/issues/${issueId}`,
          )
          .then((res) => {
            setStageData(res.data);
            if (testCaseId === undefined) {
              navigate(`testcases/${res.data.tests[0].testCaseId}`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, productId, pgroupId, issueTypeId, issueId]);

  if (
    stageData === undefined ||
    productId === undefined ||
    pgroupId === undefined ||
    stageData === undefined
  ) {
    return <LoadingSpinner />;
  }

  const backClicked = () => {
    if (selectedIndex >= 1) {
      setSelectedIndex((prev) => prev - 1);
      navigate(`../${stageData.tests[selectedIndex - 1].testCaseId}`, { relative: 'path' });
    } else {
      setSelectedIndex(stageData.tests.length - 1);
      navigate(`../${stageData.tests[stageData.tests.length - 1].testCaseId}`, {
        relative: 'path',
      });
    }
  };

  const forwardClicked = () => {
    if (selectedIndex < stageData.tests.length - 1) {
      setSelectedIndex((prev) => prev + 1);
      navigate(`../${stageData.tests[selectedIndex + 1].testCaseId}`, { relative: 'path' });
    } else {
      setSelectedIndex(0);
      navigate(`../${stageData.tests[0].testCaseId}`, { relative: 'path' });
    }
  };

  const icons: PageHeaderIconModel[] = [
    {
      caption: 'Next Test',
      icon: 'fa-caret-right',
      onClick: forwardClicked,
    },
    {
      caption: 'Previous Test',
      icon: 'fa-caret-left',
      onClick: backClicked,
    },
  ];

  return (
    <div>
      <PageHeader
        mainTitle="Failure Matrix"
        subTitle={stageData.issueName + ', Test: ' + stageData.tests[selectedIndex].testCase}
        customBump={`../../../../../..`}
        icons={icons}
      />
      <PipegroupMatrix
        productId={productId}
        pgroupId={pgroupId}
        testCaseId={stageData.tests[selectedIndex].testCaseId.toString()}
        issueTypeId={issueTypeId}
        issueId={issueId}
      />
    </div>
  );
};

export default React.memo(PipeGroupFailurePage) as typeof PipeGroupFailurePage;
