import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useConfiguration } from './configuration-provider';
import axios, { AxiosInstance } from 'axios';

export interface AuthUser {
  userId: string;
  userPrincipalName: string;
}

/*
 * Our State.
 */
export interface IServiceInterface {
  getDataApi: () => Promise<AxiosInstance>;
}

export const ServiceProviderContext = React.createContext<IServiceInterface>(
  {} as IServiceInterface,
);

/*
 * Our provider.
 */
export interface IServiceProviderProps {
  children: React.ReactNode;
}

export const RiderServiceProvider: React.FunctionComponent<IServiceProviderProps> = ({
  children,
}) => {
  const config = useConfiguration();

  const baseURL = `${config.apiUrl}`;
  const dataApi = React.useMemo(() => {
    return axios.create({
      baseURL,
    });
  }, [baseURL]);

  const { instance, accounts } = useMsal();

  /*
   * Allows a child of this provider to access the Bearer token on demand.
   */
  const getDataApi = React.useCallback((): Promise<AxiosInstance> => {
    return new Promise((resolve) => {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        dataApi.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;
        resolve(dataApi);
      });
    });
  }, [accounts, dataApi, instance]);

  /*
   * Provide our getToken function to our consumers
   */
  const contextValues = React.useMemo(
    () => ({
      getDataApi,
    }),
    [getDataApi],
  );

  return (
    <ServiceProviderContext.Provider value={contextValues}>
      {children}
    </ServiceProviderContext.Provider>
  );
};

/*
 * Our hook.
 */
export const useRiderServiceProvider = (): IServiceInterface =>
  React.useContext(ServiceProviderContext);

export default RiderServiceProvider;
