import './../../../App.css';
import React from 'react';
import { Grid, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import LoadingSpinner from '../../../shell/loading-spinner';
import {
  StageRecentFailureModalityModel,
  StageRecentModalityTestModel,
} from '../../../datamodels/stageanalysis.model';
import { Link } from 'react-router-dom';

interface StageAnalysisFailureModalityProps {
  failureModalities?: StageRecentFailureModalityModel[];
}

const StageAnalysisFailureModalityPage: React.FunctionComponent<
  StageAnalysisFailureModalityProps
> = ({ failureModalities }) => {
  if (failureModalities === undefined) {
    return <LoadingSpinner />;
  }

  const impactedTestRows = (props: GridDetailRowProps) => {
    const testRows = props.dataItem.impactedTests.map(
      (testItem: StageRecentModalityTestModel, i: number) => {
        return (
          <div
            key={(props.dataIndex + 1) * 20 + i}
            style={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '18px',
              display: 'block',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
              }}
            >
              <div style={{ width: '40px' }}></div>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: 'darkgoldenrod',
                  textDecoration: 'underline',
                }}
              >
                <Link to={`testcases/${testItem.testCaseId}`}>{testItem.testCase}</Link>
              </div>
            </div>
          </div>
        );
      },
    );
    return testRows;
  };

  return (
    <Grid data={failureModalities} detail={impactedTestRows}>
      <GridColumn field="issueName" title="Exception" />

      <GridColumn field="resultCount" title="Occurences" width="120px" />
    </Grid>
  );
};

export default React.memo(
  StageAnalysisFailureModalityPage,
) as typeof StageAnalysisFailureModalityPage;
