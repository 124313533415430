import './../../App.css';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import PageHeader from '../../shell/page-header';
import AccordionWidget from '../../shell/accordion-widget';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import LoadingSpinner from '../../shell/loading-spinner';
import { ServiceDetail } from '../../datamodels/servicedetail.model';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import AccordionHeader from '../../shell/accordion-header';
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';

interface ServicePageProps {}

const ServicePage: React.FunctionComponent<ServicePageProps> = () => {
  const { productId, serviceId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<ServiceDetail>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<ServiceDetail>(`products/${productId}/services/${serviceId}`)
        .then((res) => {
          const updated = res.data.history.map((p) => {
            p.realDate = new Date(p.resultDate);
            return p;
          });
          res.data.history = updated;
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, serviceId]);

  if (stageData === undefined || productId === undefined || serviceId === undefined) {
    return <LoadingSpinner />;
  }

  const valueAxisLabels = {
    padding: 2,
    step: 1,
    format: '{0:p1}',
  };

  return (
    <div>
      <PageHeader
        mainTitle={`${stageData.serviceName}`}
        subTitle="Failure Analysis"
        customBump=".."
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" style={{ padding: '0', marginBottom: '20px' }}>
          <AccordionWidget>
            <AccordionHeader caption="History:" boldCaption="14" smallCaption="days" />
            <Accordion.Body>
              <Chart
                style={{
                  width: '100%',
                  height: 200,
                }}
              >
                <ChartArea background="#fafafa" />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem maxDivisions={10} />
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem labels={valueAxisLabels} max="0.01" />
                </ChartValueAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    data={stageData.history}
                    type="column"
                    field="failRate"
                    categoryField="realDate"
                    colorField="barColor"
                  />
                </ChartSeries>
              </Chart>
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>

        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <AccordionHeader
              caption="Failure Modalities:"
              smallCaption="(Last 72 hours)"
              boldCaption={stageData.failureModalities.length}
            />
            <Accordion.Body style={{}}>
              <Grid
                className="rr-equal-columns"
                scrollable="none"
                data={stageData.failureModalities}
              >
                <GridColumn
                  field="logLine"
                  title="Log Signature"
                  cell={(props) => (
                    <td style={{ color: 'darkgoldenrod', textDecoration: 'underline' }}>
                      <Link to={`issues/${props.dataItem.issueLogLineId}`}>
                        {props.dataItem.logLine}
                      </Link>
                    </td>
                  )}
                />
                <GridColumn field="failureCount" title="Failures" width="100px" />
                <GridColumn field="testCount" title="Test Count" width="100px" />
              </Grid>
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(ServicePage) as typeof ServicePage;
