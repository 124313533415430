import './../../../App.css';
import React from 'react';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import { GroupSummary } from '../../../datamodels/testcasegroup.model';
import { Link, useParams } from 'react-router-dom';
import PageHeader from '../../../shell/page-header';
import LoadingSpinner from '../../../shell/loading-spinner';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

const initialSort: Array<SortDescriptor> = [{ field: 'failedCount', dir: 'desc' }];
const initialFailureSort: Array<SortDescriptor> = [{ field: 'failureCount', dir: 'desc' }];

interface PipeGroupPageProps {}

const PipeGroupPage: React.FunctionComponent<PipeGroupPageProps> = () => {
  const { productId, pgroupId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [sort, setSort] = React.useState(initialSort);
  const [failureSort, setFailureSort] = React.useState(initialFailureSort);
  const [stageData, setStageData] = React.useState<GroupSummary>();
  const [selected, setSelected] = React.useState<number>(localStorage.getItem('PipeGroupPageSelected') ? +localStorage.getItem('PipeGroupPageSelected')! : 0);

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<GroupSummary>(`products/${productId}/pgroups/${pgroupId}`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
    localStorage.setItem('PipeGroupPageSelected', e.selected.toString());
  };

  return (
    <div>
      <PageHeader mainTitle={stageData.details.name + ' Stages'} subTitle={stageData?.stages} />
      {stageData.testCases.length > 0 && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title="By Failng Test">
            <Grid
              data={orderBy(stageData.testCases, sort)}
              scrollable="none"
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
            >
              <GridColumn
                field="testCase"
                title="Test Case"
                cell={(props) => (
                  <td style={{ textDecoration: 'underline' }}>
                    <Link to={`testcases/${props.dataItem.testCaseId}`}>
                      {props.dataItem.testCase}
                    </Link>
                  </td>
                )}
              />
              <GridColumn field="failedCount" title="Failures" width="80px" />
              <GridColumn field="dynJailedCount" title="Dynamic Jailed" width="80px" />
            </Grid>
          </TabStripTab>
          <TabStripTab title="By Exception">
            <Grid
              style={{ width: '100%' }}
              data={orderBy(stageData.failures, failureSort)}

              onSortChange={(e: GridSortChangeEvent) => {
                setFailureSort(e.sort);
              }}
            >
              <GridColumn field="issueType" title="Issue Type" width="120px" />
              <GridColumn
                field="issueName"
                title="Issue"
                cell={(props) => (
                  <td style={{ textDecoration: 'underline' }}>
                    <Link
                      to={`issuetypes/${props.dataItem.issueTypeId}/issues/${props.dataItem.issueId}`}
                    >
                      {props.dataItem.issueName}
                    </Link>
                  </td>
                )}
              />
              <GridColumn field="failureCount" title="Failures" width="80px" />
              <GridColumn field="testCount" title="Tests" width="80px" />
            </Grid>
          </TabStripTab>
        </TabStrip>
      )}
    </div>
  );
};

export default React.memo(PipeGroupPage) as typeof PipeGroupPage;
