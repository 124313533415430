import './../App.css';
import React from 'react';
import { Outlet } from 'react-router-dom';

interface ContentHostProps {}

const ContentHost: React.FunctionComponent<ContentHostProps> = () => {
  return (
    <>
      <div style={{ margin: '30px' }}>
        <Outlet></Outlet>
      </div>
    </>
  );
};

export default React.memo(ContentHost) as typeof ContentHost;
