import './../../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import { GroupTestCase } from '../../../datamodels/testcasegroup.model';
import LoadingSpinner from '../../../shell/loading-spinner';

interface PipeGroupMatrixProps {
  productId: string;
  pgroupId: string;
  testCaseId: string;
  issueTypeId?: string;
  issueId?: string;
}

const PipeGroupMatrix: React.FunctionComponent<PipeGroupMatrixProps> = ({
  productId,
  pgroupId,
  testCaseId,
  issueTypeId,
  issueId,
}) => {
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<GroupTestCase>();

  React.useEffect(() => {
    if (testCaseId) {
      setStageData(undefined);

      let URL = `products/${productId}/pgroups/${pgroupId}/testcases/${testCaseId}`;
      if (issueId) {
        URL = URL.concat(`?issueTypeId=${issueTypeId}&issueId=${issueId}`);
      }
      getDataApi().then((dataApi) => {
        dataApi
          .get<GroupTestCase>(URL)
          .then((res) => {
            setStageData(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, productId, pgroupId, testCaseId]);

  const columnsToShow = stageData?.groupStages.map((item, i) => (
    <GridColumn
      field="name"
      title={item.name}
      key={i}
      headerCell={(props) => <td className="rr-center-column">{props.title}</td>}
      cell={(props) => (
        <td
          style={{
            textAlign: 'center',
            color: props.dataItem.groupBucketStages[i].testOutcome !== 'Pass' ? 'red' : 'black',
            backgroundColor:
              props.dataItem.groupBucketStages[i].issueCount > 0 ? 'goldenrod' : 'transparent',
          }}
        >
          <Link to={`buildstages/${props.dataItem.groupBucketStages[i].buildStageId}`}>
            {props.dataItem.groupBucketStages[i].testOutcome}
          </Link>
        </td>
      )}
    />
  ));

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {stageData && (
        <Grid className="rr-equal-columns" data={stageData.groupBuckets} scrollable="none">
          <GridColumn field="bucketTime" title="Execution Time" width="180px" />
          {columnsToShow}
        </Grid>
      )}
    </div>
  );
};

export default React.memo(PipeGroupMatrix) as typeof PipeGroupMatrix;
