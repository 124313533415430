import './../App.css';
import React from 'react';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccordionToggle from './accordion-toggle';
import { PageHeaderIconModel } from '../datamodels/draweritem.model';

interface AccordionHeaderProps {
  caption: string;
  boldCaption?: number | string;
  boldCaptionColor?: string;
  smallCaption?: string;
  eventKey?: string;
  icons?: PageHeaderIconModel[];
}

const AccordionHeader: React.FunctionComponent<AccordionHeaderProps> = ({
  caption,
  boldCaption,
  boldCaptionColor,
  smallCaption,
  eventKey,
  icons,
}) => {
  const iconsToShow = icons?.map((item, i) => (
    <a
      className="pc-header-icon"
      key={i}
      style={{
        fontSize: '18px',
        padding: '0px',
        paddingRight: '14px',
        color: '#FAFAFA',
        backgroundColor: '#101050',
        borderRadius: '3px',
      }}
      href={item.href}
      onClick={item.onClick}
      target="_blank"
      rel="noreferrer"
    >
      <i className={'fa ' + item.icon} style={{ marginLeft: '10px', marginRight: '8px' }} />
      <span style={{ fontSize: '15px', verticalAlign: 'bottom', cursor: 'default' }}>
        {item.caption}
      </span>
    </a>
  ));

  return (
    <div style={{ lineHeight: '36px', paddingTop: '10px' }}>
      <b
        style={{
          fontSize: '17px',
          fontWeight: '500',
        }}
      >
        {caption}
      </b>
      <span
        style={{
          color: boldCaptionColor ? boldCaptionColor : 'darkgoldenrod',
          marginLeft: '10px',
          fontSize: '33px',
          fontWeight: '800',
        }}
      >
        {boldCaption}
      </span>
      <span
        style={{
          marginLeft: '6px',
          fontSize: '13px',
          fontWeight: '400',
        }}
      >
        {' '}
        {smallCaption}{' '}
      </span>
      {iconsToShow}
      {eventKey && (
        <AccordionToggle eventKey={eventKey}>
          <FontAwesomeIcon icon={faChevronCircleDown} />
        </AccordionToggle>
      )}
    </div>
  );
};

export default React.memo(AccordionHeader) as typeof AccordionHeader;
