import './../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import LoadingSpinner from '../../shell/loading-spinner';
import PageHeader from '../../shell/page-header';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { TrainBuildSuiteTestDetails } from '../../datamodels/train.model';
import { Accordion } from 'react-bootstrap';
import AccordionWidget from '../../shell/accordion-widget';
import AccordionHeader from '../../shell/accordion-header';

interface TrainBuildTestCasePageProps {}

const TrainBuildTestCasePage: React.FunctionComponent<TrainBuildTestCasePageProps> = () => {
  const intl = useInternationalization();
  const { productId, adoBuildId, testSuiteId, testCaseId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<TrainBuildSuiteTestDetails>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<TrainBuildSuiteTestDetails>(
          `products/${productId}/trainbuilds/${adoBuildId}/testsuites/${testSuiteId}/testcases/${testCaseId}`,
        )
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, productId]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PageHeader
        mainTitle={`Build ` + stageData.buildNumber}
        subTitle={stageData.testCaseName}
        customBump="../../../.."
      />

      {stageData.trainBuildFocusSuite.testResults.length > 0 && (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" style={{ padding: '0' }}>
            <AccordionWidget>
              <AccordionHeader caption={stageData.trainBuildFocusSuite.testSuite + ':'} />
              <AccordionBody>
                <Grid scrollable="none" data={stageData.trainBuildFocusSuite.testResults}>
                  <GridColumn field="buildNumber" title="Build Number" width="100px" />
                  <GridColumn field="attempt" title="Attempt" width="50px" />
                  <GridColumn
                    field=""
                    title="Run Time"
                    width="140px"
                    cell={(props) => (
                      <td>
                        {intl.formatDate(
                          new Date(props.dataItem.testExecutionDate),
                          'MM-dd-yyyy HH:mm',
                        )}
                      </td>
                    )}
                  />
                  <GridColumn field="ciFxError" title="Error" />
                  <GridColumn field="netResult" title="NetResult" width="100px" />
                  <GridColumn
                    field="frootGuid"
                    title="Froot"
                    width="200px"
                    cell={(props) => (
                      <a
                        style={{
                          paddingLeft: '12px',
                          color: 'darkgoldenrod',
                          textDecoration: 'underline',
                        }}
                        href={`https://froot-new.azurewebsites.net/progress/${props.dataItem.frootGuid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.dataItem.frootGuid}
                      </a>
                    )}
                  />
                </Grid>
              </AccordionBody>
            </AccordionWidget>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
};

export default React.memo(TrainBuildTestCasePage) as typeof TrainBuildTestCasePage;
