import './../App.css';
import React from 'react';
import { NavLink, Route, Routes, useMatch } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import ProductPicker from './product-picker';
import ContentHost from './content-host';
import PipelinesNavigation from '../pages/pipelines/pipelines-navigation';
import PipeGroupPage from '../pages/pipelines/pipegroups-pages/pipegroup-page';
import PipeGroupTestPage from '../pages/pipelines/pipegroups-pages/pipegroup-test-page';
import PipegroupTestBuildstagePage from '../pages/pipelines/pipegroups-pages/pipegroup-test-buildstage-page';
import PullRequestFlavorPage from '../pages/pullrequests/pullrequest-flavor-page';
import PullrequestNavigation from '../pages/pullrequests/pullrequest-navigation';
import PullrequestFlavorStagesPage from '../pages/pullrequests/pullrequest-flavor-stages-page';
import PipegroupFailurePage from '../pages/pipelines/pipegroups-pages/pipegroup-failure-page';
import StageAnalysisPage from '../pages/pipelines/stageanalysis-pages/stageanalysis-page';
import StageAnalysisTestPage from '../pages/pipelines/stageanalysis-pages/stageanalysis-test-page';
import PullrequestTestPage from '../pages/pullrequests/pullrequest-test-page';
import ServicesNavigation from '../pages/services/services-navigation';
import ServicePage from '../pages/services/service-page';
import ServiceIssuePage from '../pages/services/service-issue-page';
import FlightsNavigation from '../pages/flights/flights-navigation';
import FlightGatePage from '../pages/flights/flight-gate-page';
import FlightBuildPage from '../pages/flights/flight-build-page';
import TrainsNavigation from '../pages/trains/trains-navigation';
import TrainBuildPage from '../pages/trains/train-build-page';
import TrainBuildTestcasePage from '../pages/trains/train-build-testcase-page';

interface ToolbarHostProps {}

export const ToolbarHost: React.FunctionComponent<ToolbarHostProps> = () => {
  const currentUrl = useMatch('/products/:productId/*');
  const currentProductIdString = currentUrl?.params.productId;
  const currentProductId: number | undefined = currentProductIdString
    ? +currentProductIdString
    : undefined;

  return (
    <>
      <Navbar
        className="navbar-customclass"
        variant="dark"
        expand="lg"
        style={{
          fontSize: '16px',
        }}
      >
        <Navbar.Brand href="#home" style={{ paddingLeft: '4px' }}>
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: '0px',
              marginBottom: '1px',
              marginRight: '30px',
              fontSize: '20px',
            }}
          >
            <div>RedRider</div>
            <div style={{ fontSize: '12px', marginTop: '-5px', marginLeft: '1px' }}>
              CiFx Operations
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              to={`/products/${currentProductId}/pipelines`}
              className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}
            >
              Pipelines
            </NavLink>
            <NavLink
              to={`/products/${currentProductId}/prgates`}
              className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}
            >
              Developers
            </NavLink>
            <NavLink
              to={`/products/${currentProductId}/services`}
              className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}
            >
              Services
            </NavLink>
            <NavLink
              to={`/products/${currentProductId}/trainbuilds`}
              className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}
            >
              Trains
            </NavLink>
          </Nav>
          <Nav className="m1-auto">
            <ProductPicker productId={currentProductId}></ProductPicker>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {currentProductId && (
        <Routes>
          <Route path="/products/:productId/*" element={<ContentHost />}>
            <Route
              path="pipelines"
              element={<PipelinesNavigation productId={currentProductId} />}
            ></Route>
            <Route path="pipelines/:stageFlavorId" element={<StageAnalysisPage />}></Route>
            <Route
              path="pipelines/:stageFlavorId/testcases/:testCaseId"
              element={<StageAnalysisTestPage />}
            ></Route>
            <Route path="pipelines/pgroups/:pgroupId" element={<PipeGroupPage />}></Route>
            <Route
              path="pipelines/pgroups/:pgroupId/testcases/:testCaseId"
              element={<PipeGroupTestPage />}
            ></Route>
            <Route
              path="pipelines/pgroups/:pgroupId/testcases/:testCaseId/buildstages/:buildStageId"
              element={<PipegroupTestBuildstagePage />}
            ></Route>
            <Route
              path="pipelines/pgroups/:pgroupId/issuetypes/:issueTypeId/issues/:issueId"
              element={<PipegroupFailurePage />}
            />
            <Route
              path="pipelines/pgroups/:pgroupId/issuetypes/:issueTypeId/issues/:issueId/testcases/:testCaseId"
              element={<PipegroupFailurePage />}
            />
            <Route
              path="pipelines/pgroups/:pgroupId/issuetypes/:issueTypeId/issues/:issueId/testcases/:testCaseId/buildstages/:buildStageId"
              element={<PipegroupTestBuildstagePage />}
            ></Route>

            <Route
              path="prgates"
              element={<PullrequestNavigation productId={currentProductId} />}
            />
            <Route path="prgates/stageflavors/:stageFlavorId" element={<PullRequestFlavorPage />} />
            <Route
              path="prgates/stageflavors/:stageFlavorId/issuetypes/:issueTypeId/issues/:issueId"
              element={<PullrequestFlavorStagesPage />}
            />
            <Route
              path="prgates/stageflavors/:stageFlavorId/tests/:testCaseId"
              element={<PullrequestTestPage />}
            />

            <Route path="releases" element={<FlightsNavigation productId={currentProductId} />} />
            <Route path="releases/:gateId" element={<FlightGatePage />} />
            <Route path="releases/:gateId/builds/:gateBuildId" element={<FlightBuildPage />} />

            <Route path="services" element={<ServicesNavigation productId={currentProductId} />} />
            <Route path="services/:serviceId" element={<ServicePage />} />
            <Route
              path="services/:serviceId/issues/:issueLogLineId"
              element={<ServiceIssuePage />}
            />

            <Route path="trainbuilds" element={<TrainsNavigation productId={currentProductId} />} />
            <Route path="trainbuilds/:adoBuildId" element={<TrainBuildPage />} />
            <Route
              path="trainbuilds/:adoBuildId/testsuites/:testSuiteId/testcases/:testCaseId"
              element={<TrainBuildTestcasePage />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
};
export default ToolbarHost;
