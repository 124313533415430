import './../App.css';
import React from 'react';

interface AccordionWidgetProps {
  children: React.ReactNode;
}

const AccordionWidget: React.FunctionComponent<AccordionWidgetProps> = ({ children }) => {
  return (
    <div
      style={{
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '8px',
        borderRadius: '5px',
        borderColor: '#606060',
        borderStyle: 'solid',
        borderWidth: '1px',
        backgroundColor: '#eaeaea',
      }}
    >
      {children}
    </div>
  );
};

export default React.memo(AccordionWidget) as typeof AccordionWidget;
