import './../../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import { GroupTestCase } from '../../../datamodels/testcasegroup.model';
import PageHeader from '../../../shell/page-header';
import LoadingSpinner from '../../../shell/loading-spinner';
import PipegroupMatrix from './pipegroup-matrix';

interface PipeGroupTestPageProps {}

const PipeGroupTestPage: React.FunctionComponent<PipeGroupTestPageProps> = () => {
  const { productId, pgroupId, testCaseId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<GroupTestCase>();

  React.useEffect(() => {
    if (testCaseId) {
      getDataApi().then((dataApi) => {
        dataApi
          .get<GroupTestCase>(`products/${productId}/pgroups/${pgroupId}/testcases/${testCaseId}`)
          .then((res) => {
            setStageData(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, productId, pgroupId, testCaseId]);

  if (
    stageData === undefined ||
    productId === undefined ||
    pgroupId === undefined ||
    testCaseId === undefined
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PageHeader mainTitle="Failure Matrix" subTitle={stageData?.testCase} />
      {stageData && (
        <PipegroupMatrix productId={productId} pgroupId={pgroupId} testCaseId={testCaseId} />
      )}
    </div>
  );
};

export default React.memo(PipeGroupTestPage) as typeof PipeGroupTestPage;
