import './../App.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRiderServiceProvider } from './rider-service-provider';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { IdentityModel } from '../datamodels/identity.model';

interface ProductPickerProps {
  productId: number | undefined;
}

const ProductPicker: React.FunctionComponent<ProductPickerProps> = ({ productId }) => {
  const { getDataApi } = useRiderServiceProvider();
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<IdentityModel[]>([]);
  const [currentProduct, setCurrentProduct] = React.useState<IdentityModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<IdentityModel[]>(`products`)
        .then((res) => {
          setProducts(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  React.useEffect(() => {
    const productIndex = products.findIndex((value: IdentityModel) => {
      if (value.id === productId) {
        return true;
      }
      return false;
    });

    if (productIndex >= 0) {
      setCurrentProduct(products[productIndex]);
    }
  }, [productId, products]);

  const handleProductChange = (event: DropDownListChangeEvent) => {
    navigate(`/products/${event.target.value.id}/pipelines`);
  };

  return (
    <>
      <span style={{ paddingRight: '15px', color: 'white' }}>
        <Label editorId={'productlist'}>Product:&nbsp;</Label>
        {products != null && (
          <DropDownList
            id={'productlist'}
            data={products}
            style={{ width: '100px' }}
            value={currentProduct}
            onChange={handleProductChange}
            textField="name"
            dataItemKey="id"
          />
        )}
      </span>
    </>
  );
};

export default React.memo(ProductPicker) as typeof ProductPicker;
