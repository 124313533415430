import './../App.css';
import React from 'react';

interface SectionHeaderProps {
  mainTitle: string;
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({ mainTitle }) => {
  return (
    <div className="row" style={{ marginBottom: '15px' }}>
      <div className="col-xs-12">
        <div style={{ display: 'inline-block' }}>
          <div style={{ fontSize: '22px' }}>{mainTitle}</div>
        </div>
        <table style={{ width: '100%', marginTop: '4px', marginBottom: '10px' }}>
          <tbody>
            <tr>
              <td>
                <span
                  style={{
                    float: 'left',
                    width: '50px',
                    backgroundColor: '#464775',
                    height: '3px',
                  }}
                ></span>
              </td>
              <td style={{ width: '100%', verticalAlign: 'middle' }}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#C0C0C0',
                    height: '1px',
                    verticalAlign: 'middle',
                  }}
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(SectionHeader) as typeof SectionHeader;
