import './../../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import PageHeader from '../../../shell/page-header';
import { useRiderServiceProvider } from '../../../shell/rider-service-provider';
import { TestCaseStageResults } from '../../../datamodels/result.model';
import LoadingSpinner from '../../../shell/loading-spinner';
import { PageHeaderIconModel } from '../../../datamodels/draweritem.model';

interface PipeGroupTestBuildStagePageProps {}

const PipeGroupTestBuildStagePage: React.FunctionComponent<
  PipeGroupTestBuildStagePageProps
> = () => {
  const { productId, testCaseId, buildStageId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<TestCaseStageResults>();

  React.useEffect(() => {
    if (testCaseId) {
      getDataApi().then((dataApi) => {
        dataApi
          .get<TestCaseStageResults>(
            `products/${productId}/testcases/${testCaseId}/stages/${buildStageId}`,
          )
          .then((res) => {
            setStageData(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, testCaseId, buildStageId]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  const icons: PageHeaderIconModel[] = [
    {
      caption: 'View in Ado',
      icon: 'fa-windows',
      href:
        stageData.details.adoTypeId === 0
          ? `https://dev.azure.com/domoreexp/Teamspace/_build/results?buildId=${stageData.details.adoId}`
          : `https://dev.azure.com/domoreexp/Teamspace/_releaseProgress?_a=release-environment-logs&releaseId=${stageData.details.adoId}&environmentId=${stageData.details.stageAdoId}`,
    },
  ];

  return (
    <div>
      <PageHeader mainTitle="Test Results" subTitle={stageData?.details.testCase} icons={icons} />
      {stageData?.results && (
        <>
          <Grid data={stageData?.results} style={{ marginTop: '30px' }} scrollable="none">
            <GridColumn field="testOutcome" title="Outcome" width="100px" />
            <GridColumn field="issueName" title="Failure Issue" />
            <GridColumn field="webBuild" title="Web Build" width="125px" />
            <GridColumn field="multiWindowBuild" title="MultiWindow" width="105px" />
            <GridColumn field="createdAt" title="Created At" width="150px" />
            <GridColumn
              field="frootGuid"
              title="Investigate"
              width="160px"
              cell={(props) => (
                <td>
                  {props.dataItem.hasVideos && (
                    <a
                      href={`https://ambitious-bay-09434341e.1.azurestaticapps.net/results/${props.dataItem.frootGuid}/videos`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Videos
                    </a>
                  )}
                  <a
                    style={{ paddingLeft: '12px' }}
                    href={`https://froot.azurewebsites.net/progress/${props.dataItem.frootGuid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Froot
                  </a>
                </td>
              )}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

export default React.memo(PipeGroupTestBuildStagePage) as typeof PipeGroupTestBuildStagePage;
