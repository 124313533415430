import './../App.css';
import React from 'react';

interface LoadingSpinnerProps {}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = () => {
  return (
    <div style={{ height: '50px', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <div className="k-loading-image" />
      </div>
    </div>
  );
};

export default React.memo(LoadingSpinner) as typeof LoadingSpinner;
