import React from 'react';
import './../App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import ToolbarHost from './toolbar-host';
import RiderServiceProvider from './rider-service-provider';

interface ShellRootProps {}

export const ShellRoot: React.FunctionComponent<ShellRootProps> = () => {
  return (
    <AuthenticatedTemplate>
      <RiderServiceProvider>
        <Routes>
          <Route path="" element={<Navigate replace to={'/products/1/pipelines'} />} />
          <Route path="/*" element={<ToolbarHost />} />
        </Routes>
      </RiderServiceProvider>
    </AuthenticatedTemplate>
  );
};

export default ShellRoot;
