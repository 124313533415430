import './../../App.css';
import React from 'react';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import AccordionWidget from '../../shell/accordion-widget';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import LoadingSpinner from '../../shell/loading-spinner';
import { StagesSummaryModel } from '../../datamodels/stageanalysis.model';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import {
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  Sparkline,
} from '@progress/kendo-react-charts';

const initialSort: Array<SortDescriptor> = [
  { field: 'result', dir: 'asc' },
  { field: 'serialFails', dir: 'desc' },
  { field: 'realDate', dir: 'desc' },
];

interface PipelinesNavigationProps {
  productId: number;
}

const PipelinesNavigation: React.FunctionComponent<PipelinesNavigationProps> = ({ productId }) => {
  const intl = useInternationalization();
  const [sort, setSort] = React.useState(initialSort);
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<StagesSummaryModel>();
  const [selected, setSelected] = React.useState<number>(
    localStorage.getItem('PipelinesNavSelected')
      ? +localStorage.getItem('PipelinesNavSelected')!
      : 0,
  );

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<StagesSummaryModel>(`products/${productId}/stageflavors`)
        .then((res) => {
          const mockData = () =>
            res.data.dailyStages.map((p) => {
              p.realDate = new Date(p.startTime);
              return p;
            });
          res.data.dailyStages = mockData();

          const mockData2 = () =>
            res.data.occasionalStages.map((p) => {
              p.realDate = new Date(p.startTime);
              return p;
            });
          res.data.occasionalStages = mockData2();

          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, productId]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
    localStorage.setItem('PipelinesNavSelected', e.selected.toString());
  };

  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <AccordionWidget>
            <Accordion.Body style={{ display: 'table', marginTop: '10px' }}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="Daily">
                  <Grid
                    data={orderBy(stageData.dailyStages, sort)}
                    onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                    }}
                  >
                    <GridColumn field="adoType" title="Type" width="140px" />
                    <GridColumn
                      field="stageName"
                      title="Name"
                      width="140px"
                      cell={(props) => (
                        <td style={{ color: 'darkgoldenrod', textDecoration: 'underline' }}>
                          <Link to={`${props.dataItem.buildStageFlavorId}`}>
                            {props.dataItem.stageName}
                          </Link>
                        </td>
                      )}
                    />
                    <GridColumn field="result" title="Last Result" width="110px" />
                    <GridColumn field="serialFails" title="Serial Fails" width="90px" />
                    <GridColumn
                      field="sparks"
                      title="Last 10"
                      width="120px"
                      cell={(props) => (
                        <td style={{ padding: 0 }}>
                          <Sparkline type="column">
                            <ChartArea height={32} width={95} />
                            <ChartValueAxis>
                              <ChartValueAxisItem min="0" max="1.4" />
                            </ChartValueAxis>
                            <ChartSeries>
                              <ChartSeriesItem
                                data={props.dataItem.sparklines}
                                type="column"
                                field="barWeight"
                                categoryField="startTime"
                                colorField="barColor"
                              />
                            </ChartSeries>
                          </Sparkline>
                        </td>
                      )}
                    />

                    <GridColumn
                      field="startTime"
                      title="Last Run Time"
                      cell={(props) => (
                        <td>
                          {intl.formatDate(new Date(props.dataItem.realDate), 'dd-MM-yyyy HH:mm')}
                        </td>
                      )}
                    />
                  </Grid>
                </TabStripTab>
                <TabStripTab title="Occasional">
                  <Grid
                    data={orderBy(stageData.occasionalStages, sort)}
                    onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                    }}
                  >
                    <GridColumn field="adoType" title="Type" width="140px" />
                    <GridColumn
                      field="stageName"
                      title="Name"
                      width="140px"
                      cell={(props) => (
                        <td style={{ color: 'darkgoldenrod', textDecoration: 'underline' }}>
                          <Link to={`${props.dataItem.buildStageFlavorId}`}>
                            {props.dataItem.stageName}
                          </Link>
                        </td>
                      )}
                    />
                    <GridColumn field="result" title="Last Result" width="110px" />
                    <GridColumn field="serialFails" title="Serial Fails" width="90px" />
                    <GridColumn
                      field="sparks"
                      title="Last 10"
                      width="120px"
                      cell={(props) => (
                        <td style={{ padding: 0 }}>
                          <Sparkline type="column">
                            <ChartArea height={32} width={95} />
                            <ChartValueAxis>
                              <ChartValueAxisItem min="0" max="1.4" />
                            </ChartValueAxis>
                            <ChartSeries>
                              <ChartSeriesItem
                                data={props.dataItem.sparklines}
                                type="column"
                                field="barWeight"
                                categoryField="startTime"
                                colorField="barColor"
                              />
                            </ChartSeries>
                          </Sparkline>
                        </td>
                      )}
                    />
                    <GridColumn
                      field="startTime"
                      title="Last Run Time"
                      cell={(props) => (
                        <td>
                          {intl.formatDate(new Date(props.dataItem.realDate), 'dd-MM-yyyy HH:mm')}
                        </td>
                      )}
                    />
                  </Grid>
                </TabStripTab>
              </TabStrip>
            </Accordion.Body>
          </AccordionWidget>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default React.memo(PipelinesNavigation) as typeof PipelinesNavigation;
