import './../../App.css';
import React from 'react';
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import SectionHeader from '../../shell/section-header';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
import { useNavigate } from 'react-router-dom';
import { ServiceSummary } from '../../datamodels/servicesummary.model';
import LoadingSpinner from '../../shell/loading-spinner';
import { Accordion } from 'react-bootstrap';
import AccordionWidget from '../../shell/accordion-widget';
import AccordionHeader from '../../shell/accordion-header';
import { PageHeaderIconModel } from '../../datamodels/draweritem.model';

interface ServicesNavigationProps {
  productId: number;
}

const ServicesNavigation: React.FunctionComponent<ServicesNavigationProps> = ({ productId }) => {
  const { getDataApi } = useRiderServiceProvider();
  const navigate = useNavigate();
  const [stageData, setStageData] = React.useState<ServiceSummary[]>([]);

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<ServiceSummary[]>(`products/${productId}/services`)
        .then((res) => {
          loadData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, productId]);

  const loadData = (newData: ServiceSummary[]) => {
    newData.forEach(function (serviceItem) {
      const updated = serviceItem.results.map((p) => {
        p.realDate = new Date(p.resultDate);
        return p;
      });
      serviceItem.results = updated;
    });
    setStageData(newData);
  };

  if (stageData === undefined || stageData.length === 0) {
    return <LoadingSpinner />;
  }

  const valueAxisLabels = {
    padding: 2,
    step: 1,
    format: '{0:p1}',
  };

  const generateServiceCells = () => {
    const serviceCells = stageData.map((serviceItem: ServiceSummary, i: number) => {
      const detailsClicked = () => {
        navigate(`${serviceItem.serviceId}`, { relative: 'path' });
      };

      const icons: PageHeaderIconModel[] = [
        {
          caption: 'Details',
          icon: 'fa-list',
          onClick: detailsClicked,
        },
      ];

      return (
        <div className="row" key={i} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <Accordion.Item eventKey="0" style={{ padding: '0' }}>
            <AccordionWidget>
              <AccordionHeader
                caption={serviceItem.serviceName + ':'}
                smallCaption="Failure Rate"
                boldCaption={(serviceItem.averageFailRate * 100).toFixed(2) + '%'}
                boldCaptionColor={serviceItem.averageFailRate > 0.001 ? '#e31313' : '#507050'}
                icons={icons}
              />
              <Accordion.Body>
                <Chart
                  style={{
                    width: '100%',
                    height: 200,
                  }}
                >
                  <ChartArea background="#fafafa" />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem maxDivisions={10} />
                  </ChartCategoryAxis>
                  <ChartValueAxis>
                    <ChartValueAxisItem labels={valueAxisLabels} max="0.01" />
                  </ChartValueAxis>
                  <ChartSeries>
                    <ChartSeriesItem
                      data={serviceItem.results}
                      type="column"
                      field="failRate"
                      categoryField="realDate"
                      colorField="barColor"
                    />
                  </ChartSeries>
                </Chart>
              </Accordion.Body>
            </AccordionWidget>
          </Accordion.Item>
          <div style={{ height: '20px' }}></div>
        </div>
      );
    });
    return serviceCells;
  };

  return (
    <>
      <div>
        <SectionHeader mainTitle="Service Reliability"></SectionHeader>
        <div>
          {stageData.length > 0 && (
            <Accordion defaultActiveKey="0">{generateServiceCells()}</Accordion>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ServicesNavigation) as typeof ServicesNavigation;
