import { LogLevel } from '@azure/msal-browser';
import { DeployConfig } from './deployConfig';

export const msalConfig = {
  auth: {
    clientId: '7e9c7344-1aa3-4d0c-a99c-9819c0545e5c',
    authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: DeployConfig.getCurrent().authRedirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          default:
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://13e63be4-a493-431b-977a-2d7aa6727a18/access_as_user'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
