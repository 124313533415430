import './../../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import { useInternationalization } from '@progress/kendo-react-intl';
import LoadingSpinner from '../../shell/loading-spinner';
import PageHeader from '../../shell/page-header';
import {
  BuildStageTest,
  BuildStageTestResult,
  FailureModalityModel,
} from '../../datamodels/buildstageflavor.model';
import { useRiderServiceProvider } from '../../shell/rider-service-provider';
interface PullRequestFlavorStagesPageProps {}

const PullRequestFlavorStagesPage: React.FunctionComponent<
  PullRequestFlavorStagesPageProps
> = () => {
  const { productId, stageFlavorId, issueTypeId, issueId } = useParams();
  const intl = useInternationalization();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<FailureModalityModel>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<FailureModalityModel>(
          `products/${productId}/stageflavors/${stageFlavorId}/issuetypes/${issueTypeId}/issues/${issueId}`,
        )
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi, stageFlavorId]);

  if (stageData === undefined || productId === undefined || stageFlavorId === undefined) {
    return <LoadingSpinner />;
  }

  const testResultRows = (props: GridDetailRowProps) => {
    const testRows = props.dataItem.impactedTests.map((testItem: BuildStageTest, i: number) => {
      const resultRows = testItem.testResults.map((resultItem: BuildStageTestResult, j: number) => {
        return (
          <div
            key={((props.dataIndex + 1) * 20 + i) * 10 + j}
            style={{
              display: 'block',
            }}
          >
            <div
              key={i * 1000 + j}
              style={{
                display: 'inline-flex',
              }}
            >
              <div style={{ width: '40px' }}></div>
              <div style={{ width: '220px' }}>
                <a
                  style={{
                    paddingLeft: '20px',
                    color: 'darkgoldenrod',
                    textDecoration: 'underline',
                  }}
                  href={`https://froot-new.azurewebsites.net/progress/${resultItem.frootGuid}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {resultItem.frootGuid}
                </a>
              </div>
              <div style={{ width: '100px' }}>{resultItem.testOutcome}</div>
              <div>{resultItem.issueName}</div>
            </div>
          </div>
        );
      });

      return (
        <div
          key={(props.dataIndex + 1) * 20 + i}
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            marginBottom: '10px',
            display: 'block',
          }}
        >
          <div
            style={{
              display: 'inline-flex',
            }}
          >
            <div style={{ width: '40px' }}></div>
            <div style={{ width: '400px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {testItem.testCase}
            </div>
            <div
              style={{ marginLeft: '5px', color: testItem.netResultId == 0 ? 'crimson' : 'black' }}
            >
              {testItem.netResult}
            </div>
          </div>
          {resultRows}
        </div>
      );
    });
    return testRows;
  };

  return (
    <div>
      {stageData && (
        <div>
          <PageHeader
            mainTitle="Failed Pull Requests"
            subTitle={stageData.issueName}
            customBump={`../../../..`}
          />
          <Grid
            style={{ paddingLeft: '2px', marginTop: '20px' }}
            data={stageData.buildStages}
            detail={testResultRows}
          >
            <GridColumn
              field="adoId"
              title="Build Id"
              width="110px"
              cell={(props) => (
                <td>
                  <a
                    style={{ textDecoration: 'underline', color: 'darkgoldenrod' }}
                    href={`https://dev.azure.com/domoreexp/Teamspace/_build/results?buildId=${props.dataItem.adoId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.dataItem.adoId}
                  </a>
                </td>
              )}
            />
            <GridColumn field="pullRequest" title="Pull Request" width="150px" />
            <GridColumn field="ownerName" title="Owner Name" width="182px" />
            <GridColumn
              field="startTime"
              title="Cifx Start Time"
              cell={(props) => (
                <td>{intl.formatDate(new Date(props.dataItem.startTime), 'dd-MM-yyyy HH:mm')}</td>
              )}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default React.memo(PullRequestFlavorStagesPage) as typeof PullRequestFlavorStagesPage;
